import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, IconInquiry, Heading, FieldCheckbox } from '../../../components';

import css from './InquiryForm.module.css';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

// NOTE: this InquiryForm is only for booking & purchase processes
// The default-inquiry process is handled differently
const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendInquiryError,
        messageAlreadySaved,
        updateInProgress,
        updateProfileError
      } = fieldRenderProps;

      const messageLabel = intl.formatMessage(
        {
          id: 'InquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'InquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'InquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const saveMsgLabel = intl.formatMessage({ id: 'InquiryForm.saveMessageLabel' });
      const updateMsgLabel = intl.formatMessage({ id: 'InquiryForm.updateMessageLabel' });
      const saveOrUpdateMsgLabel = messageAlreadySaved ? updateMsgLabel : saveMsgLabel;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const sendInquiryBtnTxt = intl.formatMessage({ id: 'InquiryForm.submitButtonText' })

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          <Heading as="h2" rootClassName={css.heading}>
            <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
          </Heading>
          <div>
            <FieldTextInput
              className={css.field}
              type="textarea"
              name="message"
              id={formId ? `${formId}.message` : 'message'}
              label={messageLabel}
              placeholder={messagePlaceholder}
              validate={messageRequired}
            />
          </div>
          <div className={css.updatecheck}>
            <FieldCheckbox id='saveMsg' name='saveMsg' value='true' label={saveOrUpdateMsgLabel} />
          </div>
          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendInquiryError} />
            {updateProfileError && <span className={css.error}>{saveOrUpdateMsgLabel} failed, please try again.</span>}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {!updateInProgress ? sendInquiryBtnTxt : `sending...`}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
  messageAlreadySaved: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;
